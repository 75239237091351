<template>
  <q-dialog persistent v-model="myalert">
    <q-card>
      <q-card-section>
        <div class="text-h6">Welcome to CNFT</div>
      </q-card-section>
      <q-card-section class="text-body2 q-pt-none">
        You invited by user: {{ rid }} <br />
        Share your profile or shop url to create your team!
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" v-close-popup @click="confirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  name: "Referer",
  setup() {
    console.log("Referer.setup");
    const route = useRoute();
    const router = useRouter();
    console.log(route.params);
    console.log(route.query.src)
    onMounted(async () => {
      console.log("Referer onMounted");

    });
    return {
      rid: route.params.rid,
      myalert: ref(true),
      redirect:ref(route.query.src)
    };
  },
  methods: {
    confirm() {
      if (localStorage.getItem("referer")) {
        localStorage.removeItem("referer");
        console.log("set local referer id to empty");
      }
      // window.location.href = this.redirect
      this.$router.push(this.redirect);
    },
  },
};
</script>
